<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Title from '@/Components/Title/Title.vue';
import { useForm } from '@inertiajs/vue3';
import { computed } from 'vue';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    showing: Boolean,
    application: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({
    application_id: '',
});
const sendForm = () => {
    form.application_id = props.application.id;
    form.post(route('location.rejected-applications.store'), {
        onSuccess: closeForm,
    });
};

const canRetract = computed(() => {
    return props.application.status !== 'accepted';
});
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <Title :title="$t('Reject')" />
            <p class="mt-3 text-base text-blue">
                <template v-if="canRetract">{{ $t('Are you sure you want to reject this applicant?') }}</template>
                <template v-else>{{ $t('To retract this applicant, contact support') }}</template>
            </p>
        </template>
        <template v-if="canRetract" #button-bar>
            <Button @click="sendForm" danger :processing="form.processing" :recentSuccessful="form.recentSuccessful">
                {{ $t('Reject') }}
            </Button>
            <Button @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>

        <template v-else #button-bar>
            <Button @click="closeForm">
                {{ $t('Close') }}
            </Button>
        </template>
    </Modal>
</template>
